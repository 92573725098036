(function () {
    var el = document.querySelector('html'),
        className = 'preload';

    try {
        Typekit.load({
            async: true,
            active: function () {
                Muller.removeClass(el, className);
            },
            inactive: function() {
                Muller.removeClass(el, className);
            }
        });
    } catch (e) {
        console.log(e);
    }

})();
